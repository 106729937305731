
import { defineComponent, ref } from 'vue';
import HttpRequest from '@/util/axios.config';
import { FormData } from './util';

export default defineComponent({
    setup() {
        const formData = ref<FormData>({
            ChargeMode: '',
            SendExpireEmailType: '',
            SendRenew: ''
        });
        HttpRequest.get('v3/web/common/manage/getChargeMode', {}, (res: FormData) => {
            Object.keys(formData.value).forEach((item) => {
                const key = item as keyof FormData;
                if (key === 'ChargeMode') {
                    formData.value[key] = res[key].toString();
                } else if (res[key] !== null) {
                    formData.value[key] = res[key].toString();
                }
            });
        });
        const submit = () => {
            HttpRequest.post('v3/web/common/installer/editChargeMode', formData.value, () => {
                // 编译通过
            });
            return true;
        };
        return {
            formData,
            submit
        };
    }
});
